body {
  margin: 0;
  background-color: #f8f8f8;
  font-family: "SFProRounded", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea,
input {
  outline: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: 4rem;
  font-weight: bold;
}
/* 
@font-face {
  font-family: "SFProRounded";
  src: url("../public/fonts/SF-Pro-Rounded-Regular.otf");
  font-weight: 400;
  font-style: normal;
} */

@font-face {
  font-family: "SFProRounded";
  src: url("../public/fonts/SF-Pro-Rounded-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SFProRounded";
  src: url("../public/fonts/SF-Pro-Rounded-Semibold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SFProRounded";
  src: url("../public/fonts/SF-Pro-Rounded-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
